import * as React from "react"
import Container from "@mui/material/Container";
import {Box, Grid, Typography} from "@mui/material";
import ResponseForm from "../components/contactform/Form";
import {sectionSpacing} from "../theme";
import Toolbar from "@mui/material/Toolbar";
import {GatsbyLink, Link} from "gatsby-material-ui-components";
import {StaticImage} from "gatsby-plugin-image";


export default () => {
  return (
    <Box>
      <Toolbar />
      <Container
        maxWidth={'md'}
      >
        <Box
          marginY={sectionSpacing}
          textAlign={'center'}
        >
          <Typography
            variant={'subtitle1'}
            fontWeight={'bold'}
            textTransform={'uppercase'}
          >
            Contact
          </Typography>
          <Typography variant={'h3'} color={'primary.main'}>
            Ik heb belangstelling
          </Typography>
          <Typography paragraph>
            Hieronder schrijft u zich in als geïnteresseerde. Inschrijven voor een woning kan alleen via het inschrijfformulier dat u kunt vinden onder <Link to={'/inschrijven'}>inschrijven</Link>.
          </Typography>
        </Box>
      </Container>
      <Container
        maxWidth={'lg'}
        sx={{
          alignSelf: 'center',
          marginY: 10
        }}
      >
        <Grid
          container
          spacing={8}
        >
          <Grid item xs={12} md={8}>
            <ResponseForm objects={[{objectcode: "N100036", label: "Vrijstaande woning"},{objectcode: "N100036", label: "Appartement"}]} />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display={{xs: 'none', md: 'flex'}}
          >
            <Box
              marginBottom={2}
              maxWidth={136}
            >
              <StaticImage
                alt={'header'}
                src={'../images/logos/dna-kleur@2x.png'}
                layout={'fullWidth'}
              />
            </Box>
            <address>
              Antennestraat 86<br />
              1322 AS Almere <br/>
              <Link href={'tel:0367370591'}>
                036 737 05 91
              </Link><br />
              <Link href={'mailto:info@dna-makelaars.nl'}>
                info@dna-makelaars.nl
              </Link><br />
              <Link
                href={'https://www.dna-makelaars.nl'}
                target={'_blank'}
              >
                www.dna-makelaars.nl
              </Link>
            </address>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
